<template>
  <div class="popup-approval">
    <b-modal
      id="popup-approval"
      ref="popup-approval"
      hide-footer
      :titleHtml="modalTitle"
      no-close-on-backdrop
      size="xl"
    >
      <ApprovalCommonData
        :mainModel="mainModel"
        :onSubmit="onSubmitting"
        v-if="renderComponent"
        v-on:submit-data="onSubmitData"
      >
      </ApprovalCommonData>
      <b-button
        style="fontweight: 600; width: 70px"
        variant="primary"
        size="sm"
        @click="onSubmit"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal"
        >Hủy</b-button
      >
    </b-modal>
     <template>
      <ConfirmApprovalModal
        :confirmAlert="confirmAlert"
        :entityId="mainModel.entityId"
        ref="confirm-approval-modal"
      />
    </template>
  </div>
</template>

<script>
import timeUtils from '@/utils/date';
import { TIME_TRIGGER, APPROVAL_TYPE } from '@/utils/constants';
import decounce from 'debounce';
import ApiService from '@/core/services/api.service';
import { makeToastSuccess, makeToastFaile, handleError } from '@/utils/common';
import ConfirmApprovalModal from './ConfirmApprovalModal';
import ApprovalCommonData from './ApprovalCommonData.vue';
import { APPROVAL_TYPE_NAME } from '@/utils/enum-name';
import {
  APPROVAL_PAYMENT_TYPE,
  APPROVAL_TYPE_ENUM,
  PAYMENT_REF_TYPE,
} from '@/utils/enum';

export default {
  data() {
    return {
      mainModel: {
        id: null,
        code: '',
        paymentDate: new Date(),
        type: 1,
        status: 1,
        amount: 0,
        vatAmount: 0,
        customerId: null,
        customerName: '',
        customerPhone: '',
        customerBankName: '',
        customerBankNo: '',
        customerAccountName: '',
        entityId: null,
        refType: PAYMENT_REF_TYPE.BILL,
        paymentType: 1,
        storeId: null,
        details: [],
      },
      confirmAlert: {
        title: '',
        text: '',
      },
      dpConfigs: timeUtils.DP_CONFIG,
      editable: true,
      listType: APPROVAL_TYPE,
      filteredOptionsCustomer: [],
      optionsCustomer: [],
      modalTitle: '',
      onSubmitting: false,
      renderComponent: false,
    };
  },
  components: { ApprovalCommonData, ConfirmApprovalModal },
  mounted() {},
  computed: {},
  methods: {
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    onSelectCustomer(option) {
      if (option.item.id) {
        this.mainModel.customerId = option.item.id;
        this.mainModel.customerPhone = option.item.phoneNo;
        this.mainModel.customerName = option.item.fullName;
      } else {
        this.mainModel.customerId = null;
        this.mainModel.customerPhone = '';
        this.mainModel.customerName = '';
      }
    },
    onSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.mainModel.customerPhone = searchQuery;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      this.debounceInput();
    },
    getListCustomer() {
      const text = this.mainModel.customerPhone
        ? this.mainModel.customerPhone.trim()
        : null;
      if (!text || text.length < 5) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomer = data.data.data;
          if (this.optionsCustomer.length > 0) {
            this.filteredOptionsCustomer = [...this.optionsCustomer];
          } else {
            this.filteredOptionsCustomer = [
              { message: 'SĐT không có trong hệ thống' },
            ];
          }
        },
      );
    },
    async showModal(entityId, data, type) {
      this.mainModel.entityId = entityId;
      const confirmedCreateApproval = await this.checkExistApproval(
        entityId,
        type,
        data.billId
      );
      if (!confirmedCreateApproval) return;

      this.onSubmitting = false;

      this.mainModel.billId = data.billId;
      this.mainModel.entityId = entityId;
      this.mainModel.refType = this.getRefTypeByType(type);
      this.mainModel.amount = data.amount;
      this.mainModel.customerId = data.customerId;
      this.mainModel.customerName = data.customerName;
      this.mainModel.customerPhone = data.customerPhone;
      this.mainModel.storeId = data.storeId;
      this.mainModel.type = type;
      this.mainModel.paymentType = data.paymentType;
      this.$refs['popup-approval'].show();
      this.renderComponent = true;
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Hoàn tiền cho ID: <a class='text-primary'>#${this.mainModel.entityId}</a></span>
              </div>`;
    },
    getRefTypeByType(type) {
      const billTypes = [
        APPROVAL_TYPE_ENUM.OVER_PAYMENT,
        APPROVAL_TYPE_ENUM.REFUND,
        APPROVAL_TYPE_ENUM.VAT,
      ];
      const orderTypes = [APPROVAL_TYPE_ENUM.DEPOSIT];
      const stockSlipTypes = [
        APPROVAL_TYPE_ENUM.BUY_OLD,
        APPROVAL_TYPE_ENUM.TRADE_IN,
      ];
      if (billTypes.includes(type)) {
        return PAYMENT_REF_TYPE.BILL;
      }
      if (orderTypes.includes(type)) {
        return PAYMENT_REF_TYPE.ORDER;
      }
      if (stockSlipTypes.includes(type)) {
        return PAYMENT_REF_TYPE.STOCK_SLIP;
      }
    },
    async checkExistApproval(entityId, type, billId = null) {
      const { isExistApproval, approval } =
        await this.checkExistApprovalByEntityId(billId, type);
        
      if (!isExistApproval) return true;

      const approvalTypeName = APPROVAL_TYPE_NAME[type];

      this.confirmAlert.title = `Tạo yêu cầu duyệt chi '${approvalTypeName}' cho hoá đơn này ?`;
      this.confirmAlert.text = `Lưu ý: đã tồn tại phiếu yêu cầu duyệt chi '${
        APPROVAL_TYPE_NAME[approval.type]
      }' cho hoá đơn ID: ${entityId}`;
      this.$refs['confirm-approval-modal'].showModal();
      const confirmed = await new Promise((resolve) => {
        this.$refs['confirm-approval-modal'].showModal();
        this.$refs['confirm-approval-modal'].$once(
          'onClickConfirmForceCreateApproval',
          (result) => {
            resolve(result);
          },
        );
      });
      return confirmed;
    },
    async checkExistApprovalByEntityId(billId, type) {
      const approvalRefundType = [
        APPROVAL_TYPE_ENUM.OVER_PAYMENT,
        APPROVAL_TYPE_ENUM.VAT,
      ];

      const params = {
        billId: billId,
        type: approvalRefundType.includes(type) ? approvalRefundType : type,
      };
      try {
        const {
          data: { data },
        } = await ApiService.query('approvals/check-existed', {
          params,
        });
        return data;
      } catch (error) {
        handleError(error);
      }
    },
    hideModal() {
      this.$refs['popup-approval'].hide();
    },
    onSubmit() {
      if (this.onSubmitting) {
        return;
      }
      this.onSubmitting = true;
    },
    onSubmitData(payload) {
      if (payload.paymentType === APPROVAL_PAYMENT_TYPE.TRANSFER) {
        if (
          !payload.customerBankName.trim() ||
          !payload.customerBankNo.trim() ||
          !payload.customerAccountName.trim()
        ) {
          this.onSubmitting = false;
          return makeToastFaile('Vui lòng nhập đầy đủ thông tin chuyển khoản!');
        }
      }
      if (!payload.approvalConfigId) {
        return makeToastFaile('Quy trình duyệt chi chưa được cấu hình!');
      }
      if (!payload.details.length) {
        return makeToastFaile('Quy trình duyệt chi không xác định bước duyệt!');
      }
      const firtStep = payload.details.find((detail) => detail.step === 1);
      if (!firtStep) {
        return makeToastFaile('Quy trình duyệt chi không hợp lệ!');
      }
      console.log({payload});
      ApiService.post('approvals', payload)
        .then(({ data }) => {
          makeToastSuccess(data.message);
            this.hideModal();
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        })
        .finally(() => {
          this.onSubmitting = false;
        });
    },
  },
};
</script>

<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
</style>
